export const data12 = [
  {
    subject: `Physics`,
    imgs: [
      {
        name: "S B NARAYANAN",
        src: "S-B-NARAYANAN.jpg",
        percentage: "95",
      },
    ],
  },
  {
    subject: `Chemistry`,
    imgs: [
      {
        name: "ARHANT JAYANT RUDR ANURAAG NAMBIYAR",
        src: "ARHANT-JAYANT-RUDR-ANURAAG-NAMBIAR.jpg",
        percentage: "95",
      },
      {
        name: "S B NARAYANAN",
        src: "S-B-NARAYANAN.jpg",
        percentage: "95",
      },
      {
        name: "NETHI HARSHITHA",
        src: "NETHI-HARSHITHA.jpg",
        percentage: "95",
      },
      {
        name: "SWAMINATH R NAIR",
        src: "SWAMINATH-R-NAIR.jpg",
        percentage: "95",
      },
      {
        name: "AKANKSHA PADHY",
        src: "AKANSHA-PADHY.jpg",
        percentage: "95",
      },
    ],
  },
  {
    subject: `Mathematics`,
    imgs: [
      {
        name: "S B NARAYANAN",
        src: "S-B-NARAYANAN.jpg",
        percentage: "97",
      },
    ],
  },
  {
    subject: `Biology`,
    imgs: [
      {
        name: "NETHI HARSHITHA",
        src: "NETHI-HARSHITHA.jpg",
        percentage: "90",
      },
    ],
  },
  {
    subject: `Economics`,
    imgs: [
      {
        name: "SAMYAK JHA",
        src: "SAMYAK-JHA.jpg",
        percentage: "98",
      },
    ],
  },
  {
    subject: `Psychology`,
    imgs: [
      {
        name: "VANDITA BHARDWAJ",
        src: "VANDITA-BHARDWAJ.jpg",
        percentage: "99",
      },
      {
        name: "ANANYA SARIL",
        src: "ANANYA-SARIL.jpg",
        percentage: "99",
      },
      {
        name: "ANANYA SINGH",
        src: "ANANYA-SINGH.jpg",
        percentage: "99",
      },
      {
        name: "REET SINGH",
        src: "REET-SINGH.jpg",
        percentage: "99",
      },
    ],
  },
  {
    subject: `Computer Science`,
    imgs: [
      {
        name: "S B NARAYANAN",
        src: "S-B-NARAYANAN.jpg",
        percentage: "97",
      },
    ],
  },
  {
    subject: `Physical Education`,
    imgs: [
      {
        name: "YASH JAIN",
        src: "YASH-JAIN.jpg",
        percentage: "98",
      },
    ],
  },
  {
    subject: `Accountancy`,
    imgs: [
      {
        name: "BANMEET SINGH ARNEJA",
        src: "BANMEET-SINGH-ARNEJA.jpg",
        percentage: "97",
      },
    ],
  },
  {
    subject: `Enterpreneurship`,
    imgs: [
      {
        name: "VANDITA BHARDWAJ",
        src: "VANDITA-BHARDWAJ.jpg",
        percentage: "98",
      },
    ],
  },
  {
    subject: `Political Science`,
    imgs: [
      {
        name: "MADHURITA BANERJEE",
        src: "MADHURITA-BANERJEE.jpg",
        percentage: "100",
      },
    ],
  },

  {
    subject: `Business Studies`,
    imgs: [
      {
        name: "BANMEET SINGH ARNEJA",
        src: "BANMEET-SINGH-ARNEJA.jpg",
        percentage: "93",
      },
      {
        name: "MEDHA M MENON",
        src: "MEDHA-M-MENON.jpg",
        percentage: "93",
      },
    ],
  },
  {
    subject: `English`,
    imgs: [
      {
        name: "RATNISHA",
        src: "RATNISHA.jpg",
        percentage: "98",
      },
      {
        name: "HARSHITA SHARMA",
        src: "HARSHITA-SHARMA.jpg",
        percentage: "98",
      },
      {
        name: "ARHANT JAYANT RUDR ANURAAG NAMBIYAR",
        src: "ARHANT-JAYANT-RUDR-ANURAAG-NAMBIAR.jpg",
        percentage: "98",
      },
      {
        name: "BANMEET SINGH ARNEJA",
        src: "BANMEET-SINGH-ARNEJA.jpg",
        percentage: "98",
      },
      {
        name: "VANDITA BHARDWAJ",
        src: "VANDITA-BHARDWAJ.jpg",
        percentage: "98",
      },
    ],
  },
  {
    subject: `IP`,
    imgs: [
      {
        name: "ANANYA SINGH",
        src: "ANANYA-SINGH.jpg",
        percentage: "95",
      },
    ],
  },
  {
    subject: `Painting`,
    imgs: [
      {
        name: "VANDITA BHARDWAJ",
        src: "VANDITA-BHARDWAJ.jpg",
        percentage: "100",
      },
      {
        name: "NITYA SRIRAM",
        src: "NITYA-SRIRAM.jpg",
        percentage: "100",
      },
    ],
  },
  {
    subject: `Hindustani Music`,
    imgs: [
      {
        name: "MADHURITA BANERJEE",
        src: "MADHURITA-BANERJEE.jpg",
        percentage: "100",
      },
    ],
  },
  {
    subject: `Sanskrit`,
    imgs: [
      {
        name: "MADHURITA BANERJEE",
        src: "MADHURITA-BANERJEE.jpg",
        percentage: "99",
      },
    ],
  },
  // {
  //   subject: `Applied Mathematics`,
  //   imgs: [
  //     {
  //       name: "RAAG",
  //       src: "RAAG.JPG",
  //       percentage: "81",
  //     },
  //   ],
  // },
];

export const data10 = [
  {
    subject: `English`,
    imgs: [
      {
        name: "RIDDHI",
        src: "RIDDHI.jpg",
        percentage: "98",
      },
    ],
  },
  {
    subject: `Hindi`,
    imgs: [
      {
        name: "YASHAANSH DUBEY",
        src: "YASHAANSH-DUBEY.jpg",
        percentage: "95",
      },
      {
        name: "ANGELINA WANGER",
        src: "ANGELINA-WANGER.jpg",
        percentage: "95",
      },
      {
        name: "PARTH GAMBHIR",
        src: "PARTH-GAMBHIR.jpg",
        percentage: "95",
      },
      {
        name: "UTKARSH BHARTI",
        src: "UTKARSH-BHARTI.jpg",
        percentage: "95",
      },
    ],
  },

  {
    subject: `sanskrit`,
    imgs: [
      {
        name: "DAKSH CHHAWAL",
        src: "DAKSH-CHHAWAL.jpg",
        percentage: "98",
      },
    ],
  },
  {
    subject: `Mathematics`,

    imgs: [
      {
        name: "MANAS PANDEY",
        src: "MANAS-PANDEY.jpg",
        percentage: "97",
      },
    ],
  },
  {
    subject: `Mathematics Basic`,
    imgs: [
      {
        name: "MOHD. SAIF",
        src: "MOHD.-SAIF.jpg",
        percentage: "86",
      },
    ],
  },
  {
    subject: `Science`,
    imgs: [
      {
        name: "KSHITIJ RAWAT",
        src: "KSHITIJ-RAWAT.jpg",
        percentage: "98",
      },
    ],
  },

  {
    subject: `Social Science`,
    imgs: [
      {
        name: "ABHIJEET KUMAR",
        src: "ABHIJIT-KUMAR.jpg",
        percentage: "99",
      },
      {
        name: "YASHAANSH DUBEY",
        src: "YASHAANSH-DUBEY.jpg",
        percentage: "99",
      },
      {
        name: "UTKARSH BHARTI",
        src: "UTKARSH-BHARTI.jpg",
        percentage: "99",
      },
    ],
  },

  // {
  //   subject: `Information Technology`,
  //   imgs: [
  //     {
  //       name: "Krish Malik",
  //       src: "Krish-Malik.jpeg",
  //       percentage: "99",
  //     },
  //     {
  //       name: "Miloni Singh",
  //       src: "Miloni-Singh.jpg",
  //       percentage: "99",
  //     },
  //     {
  //       name: "Pranav Jha",
  //       src: "Pranav-Jha.jpg",
  //       percentage: "99",
  //     },
  //     {
  //       name: "Shashwat Jha",
  //       src: "Shashwat-jha.jpg",
  //       percentage: "99",
  //     },
  //   ],
  // },
  {
    subject: `Artificial Intelligence`,
    imgs: [
      {
        name: "MD. SHARIQ ALAM",
        src: "MD.SHARIQ-ALAM.jpg",
        percentage: "99",
      },
      {
        name: "MANAS PANDEY",
        src: "MANAS-PANDEY.jpg",
        percentage: "99",
      },
      {
        name: "AASHNA",
        src: "AASHNA.jpg",
        percentage: "99",
      },
    ],
  },
  {
    subject: `Painting`,
    imgs: [
      {
        name: "HARSHAL DANG",
        src: "HARSHAL-DANG.jpg",
        percentage: "85",
      },
    ],
  },
];

export const class12 = [
  {
    stream: "Science",
    name: "ARHANT JAYANT RUDR ANURAAG NAMBIYAR",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/2024/class-12/ARHANT-JAYANT-RUDR-ANURAAG-NAMBIAR.jpg",
    percentage: "96%",
  },
  {
    stream: "Commerce",
    name: "BANMEET SINGH ARNEJA",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/2024/class-12/BANMEET-SINGH-ARNEJA.jpg",
    percentage: "94.6%",
  },
  {
    stream: "Humanities",
    name: "MADHURITA BANERJEE",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/2024/class-12/MADHURITA-BANERJEE.jpg",
    percentage: "98.2%",
  },
  {
    stream: "Humanities",
    name: "VANDITA BHARDWAJ",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/2024/class-12/VANDITA-BHARDWAJ.jpg",
    percentage: "98.2%",
  },
];

export const class10 = [
  {
    name: "ABHIJEET KUMAR",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/2024/class-10/ABHIJIT-KUMAR.jpg",
    percentage: "96.6%",
  },
  {
    name: "YASHAANSH DUBEY",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/2024/class-10/YASHAANSH-DUBEY.jpg",
    percentage: "95.6%",
  },
  {
    name: "LAKSHIT MISHRA",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/2024/class-10/LAKSHIT-MISHRA.jpg",
    percentage: "95.6%",
  },
  {
    name: "UTKARSH BHARTI",
    img: "https://chinmayavvdelhi.ac.in/assets/images/results/2024/class-10/UTKARSH-BHARTI.jpg",
    percentage: "94.8%",
  },
];
